export const randomItem = (array: Array<any>) => array[Math.floor(Math.random() * array.length)]

export const randomChadIndex = (min: number, max: number) => { 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomIndexWithExclusion = (array: string[], excludeIndex: number) => {
    var rand = null;
    while (rand === null || rand === excludeIndex) {
        rand = Math.floor(Math.random() * array.length)
    }
    return rand
}