import React from "react";
import "./App.css";
import ChadTextScramble from "./ChadScramble";
import { ChadScrambleTexts } from "./ChadTypes";
import ToggleMute from "./components/Toggle";

const texts: ChadScrambleTexts = [
  "chads dont see color",
  "chads dont know religion",
  "chads dont do politics",
  "chads dont get pip'd",
  "chads dont work on fridays",
  "chads dont play 60fps",
  "chads dont use tls 1.0",
  "chads dont use http",
  "chads dont do PRs",
  "chads dont pay for netflix",
  "chads dont pay for nudes",
  "chads dont lc easy",
];

function App() {
  return (
    <div className="App">
      <header className="greeting">
        <h1 className="blue">Слава</h1>
        <h1 className="yellow">Україні!</h1>
      </header>
      <body>
      <video id="dance" autoPlay muted loop>
        <source src="dance.mp4" type="video/mp4"></source>
      </video>
    </body>
      <footer className="App-scramble">
        <ChadTextScramble texts={texts} letterSpeed={10} nextLetterSpeed={200} />
        <ToggleMute />
      </footer>
    </div>
  );
}

export default App;
