import React, { useState } from "react";

import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";
import HeadsetIcon from "@mui/icons-material/Headset";
import Button from "@mui/material/Button";

function ToggleMute() {
  const [mute, setMute] = useState(true);

  const handleClick = () => {
    const video = document.getElementById("dance") as HTMLVideoElement;
    setMute(!mute);
    video.muted = !mute;
  };

  return (
    <Button id="fade-button" onClick={handleClick}>
      {mute ? <HeadsetOffIcon /> : <HeadsetIcon />}
    </Button>
  );
}

export default ToggleMute;
